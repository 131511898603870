import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 960.000000 518.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,518.000000) scale(0.100000,-0.100000)">
<path d="M250 4655 l0 -275 4519 0 4518 0 7 61 c8 79 8 368 0 437 l-7 52
-4518 0 -4519 0 0 -275z"/>
<path d="M1655 4101 c-175 -20 -357 -72 -520 -150 -83 -39 -236 -130 -255
-151 -3 -3 -30 -24 -61 -47 -76 -56 -205 -190 -278 -287 -364 -486 -384 -1141
-49 -1656 30 -47 93 -128 141 -181 419 -470 1087 -650 1702 -459 507 158 908
560 1039 1043 126 462 30 926 -269 1296 -237 295 -550 484 -937 566 -135 29
-378 41 -513 26z m364 -787 c300 -78 532 -310 582 -581 18 -95 6 -265 -25
-352 -152 -432 -654 -633 -1080 -432 -145 68 -247 160 -326 292 -151 254 -128
574 57 804 185 228 516 340 792 269z"/>
<path d="M4102 4091 c-214 -53 -353 -190 -414 -407 l-23 -79 -3 -1232 -3
-1233 416 0 415 0 0 608 c0 558 8 1037 17 1028 8 -8 70 -244 218 -836 48 -190
93 -365 100 -390 8 -25 23 -79 35 -120 29 -103 67 -193 100 -239 93 -128 385
-135 488 -11 22 26 68 122 89 185 35 102 216 778 279 1040 23 94 105 389 110
394 2 2 4 -370 4 -827 l0 -832 413 2 412 3 3 1175 c2 831 0 1198 -8 1255 -13
86 -55 213 -94 279 -51 87 -168 181 -271 216 -170 58 -484 48 -648 -22 -75
-32 -122 -73 -176 -154 -54 -80 -81 -157 -181 -519 -135 -486 -175 -617 -183
-595 -3 8 -40 143 -82 300 -167 622 -185 680 -232 771 -57 110 -126 172 -238
212 -138 49 -402 62 -543 28z"/>
<path d="M7955 4099 c-481 -87 -764 -330 -881 -759 -58 -214 -56 -174 -61
-1221 l-4 -966 38 -6 c21 -4 208 -7 416 -7 l377 0 0 434 0 434 188 6 c342 12
479 32 657 98 302 110 496 322 570 622 71 287 49 571 -61 788 -42 84 -138 211
-206 273 -139 127 -348 238 -523 279 -146 34 -393 46 -510 25z m276 -811 c57
-19 107 -60 136 -113 25 -44 28 -60 28 -137 l0 -88 -40 -40 c-68 -67 -128 -83
-337 -88 l-178 -4 -2 64 c-6 236 25 337 117 385 16 9 41 19 55 24 39 12 183
10 221 -3z"/>
<path d="M250 570 l0 -270 4519 0 4519 0 7 83 c3 45 5 165 3 267 l-3 185
-4522 3 -4523 2 0 -270z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
